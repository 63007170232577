// src/pages/AllItemsPage.js
import React, { useEffect, useState } from 'react';
import ItemCard from '../components/ItemCard';
import api from '../api';
import './AllItemsPage.css'; // optional CSS for styling

const AllItemsPage = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Our search box input
    const [searchTerm, setSearchTerm] = useState('');

    // We'll fetch items initially (when searchTerm is empty).
    useEffect(() => {
        fetchAllItems();
    }, []);

    // function to fetch all items (non-hidden for non-admin, all for admin)
    const fetchAllItems = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await api.get('/items/all');
            setItems(response.data);
        } catch (err) {
            console.error(err);
            setError('Failed to fetch items');
        } finally {
            setLoading(false);
        }
    };

    // function to do special search
    const fetchSpecialSearch = async (term) => {
        setLoading(true);
        setError(null);
        try {
            const response = await api.get('/items/search-special', {
                params: { term }
            });
            setItems(response.data);
        } catch (err) {
            console.error(err);
            setError('Failed to search items');
        } finally {
            setLoading(false);
        }
    };

    // Handler for changes to the search input
    const handleSearchChange = (e) => {
        const newTerm = e.target.value;
        setSearchTerm(newTerm);

        if (!newTerm.trim()) {
            // If empty, revert to /items/all
            fetchAllItems();
        } else {
            // If non-empty, call special search
            fetchSpecialSearch(newTerm);
        }
    };

    return (
        <div className="all-items-container">
            <h1>All Items</h1>

            <div className="all-items-search-container">
                <label htmlFor="search-items" style={{ marginRight: '8px' }}>
                    Search:
                </label>
                <input
                    id="search-items"
                    type="text"
                    placeholder="Description or SKU..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>

            {loading && <p>Loading...</p>}
            {error && <p className="error-text">{error}</p>}

            {!loading && !error && (
                <div className="items-grid">
                    {items.map(item => (
                        <ItemCard key={item.itemId} item={item} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default AllItemsPage;