// src/pages/AdminPricingPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api';
import './AdminPricingPage.css';

const AdminPricingPage = () => {
    const { itemId } = useParams();
    const [item, setItem] = useState(null);
    const [pricingTiers, setPricingTiers] = useState([]);

    const [editDepositMode, setEditDepositMode] = useState(false);
    const [depositAmount, setDepositAmount] = useState('');

    const [newTier, setNewTier] = useState({
        minNights: '',
        maxNights: '',
        pricePerNight: '',
    });

    useEffect(() => {
        const fetchItemAndPricing = async () => {
            const itemResponse = await api.get(`/items/${itemId}`);
            setItem(itemResponse.data);

            if (itemResponse.data.deposit !== null && itemResponse.data.deposit !== undefined) {
                setDepositAmount(itemResponse.data.deposit);
            }

            const pricingResponse = await api.get(`/admin/pricing/item/${itemId}`);
            setPricingTiers(pricingResponse.data);
        };
        fetchItemAndPricing();
    }, [itemId]);

    // Handle changes in deposit input
    const handleDepositChange = (e) => {
        setDepositAmount(e.target.value);
    };

    // Submit deposit update to backend
    const handleUpdateDeposit = async () => {
        try {
            const depositValue = parseFloat(depositAmount);
            if (isNaN(depositValue) || depositValue < 0) {
                alert('Please enter a valid deposit amount.');
                return;
            }

            // PUT request to update deposit
            await api.put(
                `/items/admin/items/${itemId}/deposit?deposit=${depositValue}`
            );

            // Optionally, refresh the item to reflect new deposit value
            const itemResponse = await api.get(`/items/${itemId}`);
            setItem(itemResponse.data);

            alert(`Deposit updated to: $${depositValue.toFixed(2)}`);
        } catch (err) {
            console.error('Failed to update deposit:', err);
            alert('Error updating deposit.');
        }
    };

    const handleInputChange = (e) => {
        setNewTier({
            ...newTier,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddPricingTier = async () => {
        const newTierData = {
            minNights: parseInt(newTier.minNights, 10),
            maxNights: newTier.maxNights ? parseInt(newTier.maxNights, 10) : null,
            pricePerNight: parseFloat(newTier.pricePerNight),
        };

        await api.post(`/admin/pricing/item/${itemId}`, newTierData);
        await api.put(`/items/${itemId}/updateTimestamp`);
        // Refresh the pricing tiers
        const response = await api.get(`/admin/pricing/item/${itemId}`);
        setPricingTiers(response.data);
        // Reset the form
        setNewTier({
            minNights: '',
            maxNights: '',
            pricePerNight: '',
        });
    };

    const handleDeletePricingTier = async (tierId) => {
        await api.delete(`/admin/pricing/${tierId}`);
        await api.put(`/items/${itemId}/updateTimestamp`);
        // Refresh the pricing tiers
        const response = await api.get(`/admin/pricing/item/${itemId}`);
        setPricingTiers(response.data);
    };

    return (
        <div className="admin-pricing-container">
            <h1>Manage Pricing for {item?.description}</h1>

            {/* ====== DEPOSIT MANAGEMENT ====== */}
            <div className="deposit-section">
                <div className="deposit-row" style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <label style={{marginRight: '10px'}}>
                        Deposit Amount
                        <span
                            className="deposit-info-icon"
                            title="Value of the item which a renter will be charged upon failure to return the rental item"
                            style={{
                                marginLeft: '5px',
                                cursor: 'help',
                                color: '#000',
                                // Using the "information source" character (U+2139)
                                fontSize: '1.2rem',
                                lineHeight: '1rem',
                                display: 'inline-block',
                            }}
                        >
        ⓘ: {/* info icon */}
      </span>
                    </label>

                    {/* If we're NOT editing, just show the bold deposit text */}
                    {!editDepositMode && (
                        <span
                            style={{fontWeight: 'bold', cursor: 'pointer'}}
                            onClick={() => setEditDepositMode(true)}
                        >
        ${Number(depositAmount).toFixed(2)}
      </span>
                    )}

                    {/* If we're in edit mode, show the input + Update button */}
                    {editDepositMode && (
                        <>
                            <input
                                type="number"
                                step="0.01"
                                value={depositAmount}
                                onChange={handleDepositChange}
                                style={{
                                    fontWeight: 'bold',
                                    width: '120px',
                                }}
                                autoFocus
                            />
                            <button
                                onClick={async () => {
                                    await handleUpdateDeposit();
                                    setEditDepositMode(false);
                                }}
                            >
                                Update Deposit
                            </button>
                        </>
                    )}
                </div>
            </div>

            <h2>Existing Pricing Tiers</h2>
            <table className="pricing-table">
                <thead>
                <tr>
                    <th>Min Nights</th>
                    <th>Max Nights</th>
                    <th>Price Per Night</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {pricingTiers.map((tier) => (
                    <tr key={tier.id}>
                        <td>{tier.minNights}</td>
                        <td>{tier.maxNights || 'No Max'}</td>
                        <td>${tier.pricePerNight.toFixed(2)}</td>
                        <td>
                            <button onClick={() => handleDeletePricingTier(tier.id)}>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <h2>Add New Pricing Tier</h2>
            <form
                className="add-pricing-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleAddPricingTier();
                }}
            >
                <label>
                    Min Nights:
                    <input
                        type="number"
                        name="minNights"
                        value={newTier.minNights}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                <label>
                    Max Nights:
                    <input
                        type="number"
                        name="maxNights"
                        value={newTier.maxNights}
                        onChange={handleInputChange}
                    />
                </label>
                <label>
                    Price Per Night:
                    <input
                        type="number"
                        step="0.01"
                        name="pricePerNight"
                        value={newTier.pricePerNight}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                <button type="submit">Add Pricing Tier</button>
            </form>
        </div>
    );
};

export default AdminPricingPage;