// src/pages/HomePage.js
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import api from '../api';

// Define the category IDs for each major group
const CAMERA_CATEGORY_IDS = [148, 157, 153];
const LENS_CATEGORY_IDS = [149, 158, 154];
const VIDEO_CATEGORY_IDS = [239, 243];

// Optional placeholder if no item image is found
const PLACEHOLDER_IMG_CAMERAS = 'https://via.placeholder.com/400x250?text=No+Image';
const PLACEHOLDER_IMG_LENSES = 'https://via.placeholder.com/400x250?text=No+Image';
const PLACEHOLDER_IMG_VIDEO = 'https://via.placeholder.com/400x250?text=No+Image';

const HomePage = () => {
    const navigate = useNavigate();

    // We'll store one "featured image" per category
    const [cameraImg, setCameraImg] = useState('');
    const [lensImg, setLensImg] = useState('');
    const [videoImg, setVideoImg] = useState('');

    // Reference to the container so we can measure its parent
    const containerRef = useRef(null);
    // State to hold the dynamic scale factor
    const [scale, setScale] = useState(1);

    useEffect(() => {
        // 1) Fetch images
        const fetchFirstItemImage = async (categoryIds) => {
            for (const catId of categoryIds) {
                try {
                    const response = await api.get(`/items/category/${catId}`);
                    const items = response.data || [];

                    // Filter out hidden or items that lack an imageUrl
                    const visibleItems = items.filter(
                        (item) => !item.hidden && item.imageUrl && item.imageUrl.trim() !== ''
                    );

                    // Return the first valid image
                    if (visibleItems.length > 0) return visibleItems[0].imageUrl;
                } catch (err) {
                    console.error('Error fetching items from category:', catId, err);
                }
            }
            return null;
        };

        fetchFirstItemImage(CAMERA_CATEGORY_IDS).then((url) => {
            setCameraImg(url || PLACEHOLDER_IMG_CAMERAS);
        });
        fetchFirstItemImage(LENS_CATEGORY_IDS).then((url) => {
            setLensImg(url || PLACEHOLDER_IMG_LENSES);
        });
        fetchFirstItemImage(VIDEO_CATEGORY_IDS).then((url) => {
            setVideoImg(url || PLACEHOLDER_IMG_VIDEO);
        });

        // 2) Set up a function to update the scale factor based on the available width
        function handleResize() {
            if (!containerRef.current) return;

            // The container has a fixed width of 960px in our CSS
            const baseWidth = 960;

            // The parentElement is typically the browser viewport or a wrapping container
            const parentWidth = containerRef.current.parentElement.clientWidth;

            // If the parent is narrower than 960, shrink the container
            if (parentWidth < baseWidth) {
                setScale(parentWidth / baseWidth);
            } else {
                // Otherwise, stay at 100% (scale = 1)
                setScale(1);
            }
        }

        // Listen for window resize
        window.addEventListener('resize', handleResize);

        // Run once on mount
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Navigation handlers
    const handleNavigateCameras = () => {
        navigate('/cameras?brand=All');
    };
    const handleNavigateLenses = () => {
        navigate('/lenses?brand=All');
    };
    const handleNavigateVideo = () => {
        navigate('/video-production');
    };

    return (
        // Apply the dynamic scale to the home-container inline
        <div
            ref={containerRef}
            className="home-container"
            style={{
                transform: `scale(${scale})`,
                transformOrigin: 'top left'
            }}
        >
            <h1 className="home-heading">Welcome to Rent Ranger</h1>
            <div className="home-grid">
                {/* Cameras */}
                <div className="home-card" onClick={handleNavigateCameras}>
                    <div className="home-card-title">Cameras</div>
                    <div className="home-card-image">
                        <img src={cameraImg} alt="Cameras" />
                    </div>
                    <div className="home-card-description">
                        Browse our selection of DSLR and Mirrorless cameras from Canon, Sony, and Nikon.
                    </div>
                </div>

                {/* Lenses */}
                <div className="home-card" onClick={handleNavigateLenses}>
                    <div className="home-card-title">Lenses</div>
                    <div className="home-card-image">
                        <img src={lensImg} alt="Lenses" />
                    </div>
                    <div className="home-card-description">
                        Explore our lenses to fit your shooting style.
                    </div>
                </div>

                {/* Video Production */}
                <div className="home-card" onClick={handleNavigateVideo}>
                    <div className="home-card-title">Video Production</div>
                    <div className="home-card-image">
                        <img src={videoImg} alt="Video Production" />
                    </div>
                    <div className="home-card-description">
                        Lighting equipment, audio gear, and all the accessories you need for your next shoot.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
