import React, {useEffect, useState} from "react";
import api from "../api";


const PrivacyPolicyPage = () => {
    const [privacyPolicyText, setPrivacyPolicyText] = useState('');

    useEffect(() => {
        const fetchPolicies = async () => {
            try {
                const privacyResp = await api.get('/privacy-policy');
                setPrivacyPolicyText(privacyResp.data);

            } catch (error) {
                console.error('Failed to load Privacy Policy', error);
            }
        };
        fetchPolicies();
    }, []);


    return (

        <div className="item-detail">
            <h2>Privacy Policy</h2>
            <p>
                {privacyPolicyText}
            </p>
        </div>

    )

}

export default PrivacyPolicyPage;