import React from 'react';
import { Nav, Navbar } from "react-bootstrap";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";
import './Footer.css';

const Footer = () => {
    return (
        <Navbar style={{ backgroundColor: 'transparent' }} expand="sm">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <div className="footer-container">
                        <p className="footer-copyright">&copy; 2024 Rent Ranger. All rights reserved.</p>
                        <Nav className="footer-links">
                            <Nav.Link as={NavLink} to="/privacy-policy">Privacy Policy</Nav.Link>
                            <Nav.Link as={NavLink} to="/terms">Terms and Conditions</Nav.Link>
                        </Nav>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Footer;
