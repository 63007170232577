// src/pages/AdminUserDetailsPage.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import api from '../api';
import './AdminUserDetailsPage.css';

const AdminUserDetailsPage = () => {
    const { userId } = useParams(); // from /admin/users/:userId
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    // Address form states
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [stateVal, setStateVal] = useState('');
    const [zip, setZip] = useState('');
    const [updateMsg, setUpdateMsg] = useState('');

    // For reassigning tax category
    const [taxCategories, setTaxCategories] = useState([]);       // all available tax categories
    const [newTaxCategoryId, setNewTaxCategoryId] = useState(''); // selected from the dropdown
    const [taxUpdateMsg, setTaxUpdateMsg] = useState('');

    // 1) Fetch user details
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                // GET /users/{id} => returns entire User object
                const response = await api.get(`/users/${userId}`);
                setUser(response.data);

                // Initialize address form
                setAddress1(response.data.address1 || '');
                setAddress2(response.data.address2 || '');
                setCity(response.data.city || '');
                setStateVal(response.data.state || '');
                setZip(response.data.zip || '');

            } catch (err) {
                console.error('Failed to fetch user details:', err);
                setError('Failed to load user details');
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [userId]);

    // 2) Fetch all tax categories for the dropdown
    useEffect(() => {
        const fetchTaxCategories = async () => {
            try {
                const resp = await api.get('/lightspeed/taxes');
                setTaxCategories(resp.data);
            } catch (err) {
                console.error('Failed to load tax categories:', err);
            }
        };
        fetchTaxCategories();
    }, []);

    if (loading) return <p>Loading user details...</p>;
    if (error) return <p>{error}</p>;
    if (!user) return <p>User not found</p>;

    // Handle address update
    const handleSubmitAddress = async (e) => {
        e.preventDefault();
        setUpdateMsg('');

        try {
            // PUT /users/{userId}/address
            const response = await api.put(`/users/${userId}/address`, {
                address1,
                address2,
                city,
                state: stateVal,
                zip
            });
            setUpdateMsg(response.data);

            // Re-fetch user to see updated data
            const refetch = await api.get(`/users/${userId}`);
            setUser(refetch.data);
        } catch (err) {
            console.error('Failed to update address:', err);
            if (err.response && err.response.data) {
                setUpdateMsg(err.response.data);
            } else {
                setUpdateMsg('Failed to update address');
            }
        }
    };

    // Handle reassigning tax category
    const handleAssignTaxCategory = async () => {
        if (!newTaxCategoryId) {
            setTaxUpdateMsg("Please select a Tax Category.");
            return;
        }
        try {
            // e.g. PUT /users/{userId}/assign-tax?taxCategoryId=xxx
            await api.put(`/users/${userId}/assign-tax?taxCategoryId=${newTaxCategoryId}`);
            setTaxUpdateMsg(`Tax category updated to ${newTaxCategoryId}`);

            // Re-fetch user to refresh assignedTaxCategoryId
            const refetch = await api.get(`/users/${userId}`);
            setUser(refetch.data);
        } catch (err) {
            console.error("Failed to update user's tax category:", err);
            setTaxUpdateMsg("Failed to update tax category. Check console/logs.");
        }
    };

    return (
        <div className="admin-user-details-container">
            <h2>User Details (Admin View)</h2>

            <div className="user-info-section">
                <p><strong>Customer ID:</strong> {user.customerId}</p>
                <p><strong>Company:</strong> {user.company}</p>
                <p><strong>Name:</strong> {user.firstName} {user.lastName}</p>
                <p><strong>Email:</strong> {user.email}</p>
                <p><strong>Username:</strong> {user.username}</p>
                <p><strong>Mobile:</strong> {user.phone}</p>
                <p><strong>Work Phone:</strong> {user.workPhone}</p>

                {/* Display current assignedTaxCategoryId */}
                <p><strong>Assigned Tax Category:</strong>
                    {user.assignedTaxCategoryId ? user.assignedTaxCategoryId : 'None'}
                </p>
            </div>

            <div className="address-section">
                <h3>Update Address</h3>
                <Form onSubmit={handleSubmitAddress} className="address-form">
                    <Form.Group className="mb-2">
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                            type="text"
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Address 2 (optional)</Form.Label>
                        <Form.Control
                            type="text"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            type="text"
                            value={stateVal}
                            onChange={(e) => setStateVal(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                            type="text"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Update Address
                    </Button>
                </Form>
                {updateMsg && <p className="update-message">{updateMsg}</p>}
            </div>

            <hr />

            {/* Drop-down UI to reassign the user's tax category */}
            <div style={{ margin: '1em 0' }}>
                <h3>Reassign Tax Category</h3>
                <p>
                    Current: <strong>{user.assignedTaxCategoryId ? user.assignedTaxCategoryId : 'None'}</strong>
                </p>

                <Form.Group className="mb-2" style={{ maxWidth: '300px' }}>
                    <Form.Label>New Tax Category</Form.Label>
                    <Form.Select
                        value={newTaxCategoryId}
                        onChange={(e) => setNewTaxCategoryId(e.target.value)}
                    >
                        <option value="">-- Select Tax Category --</option>
                        {taxCategories.map(tax => (
                            <option key={tax.taxCategoryID} value={tax.taxCategoryID}>
                                {tax.tax1Name}
                                {/* Optionally display the rate: */}
                                {' '}({(tax.tax1Rate * 100).toFixed(2)}%)
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Button variant="success" onClick={handleAssignTaxCategory}>
                    Update Tax Category
                </Button>
                {taxUpdateMsg && <p style={{ marginTop: '0.5em', color: 'green' }}>{taxUpdateMsg}</p>}
            </div>

            <Button variant="secondary" onClick={() => navigate('/admin/manage-users')}>
                Back to Manage Users
            </Button>
        </div>
    );
};

export default AdminUserDetailsPage;