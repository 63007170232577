// src/pages/AdminDiscountsPage.js

import React, { useEffect, useState } from 'react';
import api from '../api';
import './AdminDiscountsPage.css';

const AdminDiscountsPage = () => {
    const [discounts, setDiscounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchDiscounts = async () => {
        try {
            const response = await api.get('/lightspeed/discounts');
            setDiscounts(response.data);
        } catch (err) {
            console.error('Failed to load discounts:', err);
            setError('Failed to load discounts');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDiscounts();
    }, []);

    const handleToggleActive = async (discountId, currentActive) => {
        // We want to set it to the opposite
        const newActive = !currentActive;
        try {
            await api.put(`/lightspeed/discounts/${discountId}/active?active=${newActive}`);
            // Re-fetch or manually update your list
            fetchDiscounts();
        } catch (err) {
            console.error('Failed to toggle discount active state:', err);
            alert('Failed to toggle discount active state');
        }
    };

    if (loading) return <p className="loading-text">Loading discounts...</p>;
    if (error) return <p className="error-text">{error}</p>;

    return (
        <div className="admin-discounts-container">
            <h1 className="admin-discounts-title">Lightspeed Discounts</h1>
            {discounts.length === 0 ? (
                <p>No discounts found.</p>
            ) : (
                <table className="admin-discounts-table">
                    <thead>
                    <tr>
                        <th>Discount ID</th>
                        <th>Name</th>
                        <th>Discount Amount</th>
                        <th>Discount Percent</th>
                        <th>Requires Customer?</th>
                        <th>Archived?</th>
                        <th>Last Modified</th>
                        <th>Active?</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {discounts.map((discount) => {
                        const isActive = discount.isActive; // from local DB
                        return (
                            <tr key={discount.discountID}>
                                <td>{discount.discountID}</td>
                                <td>{discount.name}</td>
                                <td>{discount.discountAmount}</td>
                                <td>{discount.discountPercent}</td>
                                <td>{discount.requireCustomer === "true" ? "Yes" : "No"}</td>
                                <td>{discount.archived === "true" ? "Yes" : "No"}</td>
                                <td>{discount.timeStamp}</td>
                                <td>{isActive ? "Active" : "Inactive"}</td>
                                <td>
                                    <button
                                        onClick={() => handleToggleActive(discount.discountID, isActive)}
                                        className="toggle-active-btn"
                                    >
                                        {isActive ? "Deactivate" : "Activate"}
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AdminDiscountsPage;
