/* Welcome/Landing Page */
import React from 'react';
import { Typography, Box, Container } from '@mui/material';

const Home = () => {
    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Box sx={{ textAlign: 'center', py: 5 }}>
                <Typography variant="h3" gutterBottom>
                    Welcome to Rent Ranger
                </Typography>
                <Typography variant="h6" color="textSecondary" paragraph>
                    Your one-stop solution for all your rental needs. Browse our extensive collection of rental items,
                    including cameras, audio equipment, lighting, and more!
                </Typography>
            </Box>
        </Container>
    );
};

export default Home;
