import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import './AdminUsersPage.css';

const AdminUsersPage = () => {
    const [users, setUsers] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterAdmin, setFilterAdmin] = useState(false);
    const [filterHasDiscount, setFilterHasDiscount] = useState(false);
    const navigate = useNavigate();

    // Utility to format phone into 123-456-7890 if it's 10 digits
    const formatPhoneNumber = (phone) => {
        if (!phone) return '';
        const digits = phone.replace(/\D/g, '');
        if (digits.length === 10) {
            return digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
        return phone; // fallback if not 10 digits
    };

    const fetchUsers = async () => {
        try {
            const response = await api.get('/users');
            setUsers(response.data);
        } catch (err) {
            console.error(err);
            setError('Failed to load users');
        }
    };

    const fetchActiveDiscounts = async () => {
        try {
            const response = await api.get('/lightspeed/discounts');
            // returns all discounts from Lightspeed + local DB
            // filter only active
            const activeOnes = response.data.filter(d => d.isActive === true);
            setDiscounts(activeOnes);
        } catch (err) {
            console.error('Failed to load discounts', err);
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchActiveDiscounts();
    }, []);

    const toggleAdmin = async (userId) => {
        try {
            await api.put(`/users/${userId}/toggle-admin`);
            await fetchUsers();
        } catch (err) {
            console.error(err);
            alert('Failed to update admin status');
        }
    };

    const assignDiscount = async (userId, discountId) => {
        if (!discountId) {
            // user selected --No Discount--
            try {
                await api.put(`/users/${userId}/unassign-discount`);
                await fetchUsers();
            } catch (err) {
                console.error('Failed to remove discount:', err);
                alert('Failed to remove discount');
            }
            return;
        }
        // else normal assignment
        try {
            await api.put(`/users/${userId}/assign-discount?discountId=${discountId}`);
            await fetchUsers();
        } catch (err) {
            console.error('Failed to assign discount:', err);
            alert('Failed to assign discount');
        }
    };

    if (error) return <p>{error}</p>;

    // 1) Convert phone => formatted
    const usersWithFormattedPhone = users.map(u => ({
        ...u,
        formattedPhone: formatPhoneNumber(u.phone)
    }));

    // 2) Filter by searchTerm (search phone or email)
    let filtered = usersWithFormattedPhone.filter(u => {
        const searchLower = searchTerm.toLowerCase();
        return (
            (u.formattedPhone && u.formattedPhone.toLowerCase().includes(searchLower)) ||
            (u.email && u.email.toLowerCase().includes(searchLower))
        );
    });

    // 3) Filter by admin if desired
    if (filterAdmin) {
        filtered = filtered.filter(u =>
            u.roles.some(role => role.name === 'ROLE_ADMIN')
        );
    }

    // 4) Filter by "has assigned discount" if desired
    if (filterHasDiscount) {
        filtered = filtered.filter(u => !!u.assignedDiscountId);
    }

    return (
        <div className="admin-users-container">
            <h2>Manage Users</h2>

            {/* Filters row */}
            <div className="filter-row">
                <div className="search-container">
                    <label>Search by Mobile or Email:</label>
                    <input
                        type="text"
                        placeholder="e.g. 123-456-7890 or user@example.com"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>
                <div className="checkbox-filters">
                    <label>
                        <input
                            type="checkbox"
                            checked={filterAdmin}
                            onChange={() => setFilterAdmin(!filterAdmin)}
                        />
                        Show Admin Only
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={filterHasDiscount}
                            onChange={() => setFilterHasDiscount(!filterHasDiscount)}
                        />
                        Show With Assigned Discount
                    </label>
                </div>
            </div>

            <table className="admin-users-table">
                <thead>
                <tr>
                    <th>Customer ID</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Assigned Discount</th>
                    <th>Admin?</th>
                    <th>Action</th>
                    <th>Profile</th>
                </tr>
                </thead>
                <tbody>
                {filtered.map(user => {
                    const isAdmin = user.roles.some(role => role.name === 'ROLE_ADMIN');
                    return (
                        <tr key={user.id}>
                            <td>{user.customerId}</td>
                            <td>{user.firstName} {user.lastName}</td>
                            <td>{user.formattedPhone || ''}</td>
                            <td>{user.email}</td>
                            <td>
                                {/* show assigned discount if any */}
                                {user.assignedDiscountId ? `#${user.assignedDiscountId}` : 'None'}
                                <br/>
                                {/* dropdown of active discounts to reassign */}
                                <select
                                    onChange={e => assignDiscount(user.id, e.target.value)}
                                    value={user.assignedDiscountId || ''}
                                >
                                    <option value="">--No Discount--</option>
                                    {discounts.map(d => (
                                        <option key={d.discountID} value={d.discountID}>
                                            {d.name} (ID: {d.discountID})
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>{isAdmin ? 'Yes' : 'No'}</td>
                            <td>
                                <button
                                    onClick={() => toggleAdmin(user.id)}
                                    className={isAdmin ? 'remove-admin-btn' : 'make-admin-btn'}
                                >
                                    {isAdmin ? 'Remove Admin' : 'Make Admin'}
                                </button>
                            </td>
                            <td>
                                <button
                                    onClick={() => navigate(`/admin/users/${user.id}`)}
                                    className="view-user-profile-btn"
                                >
                                    View Profile
                                </button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};

export default AdminUsersPage;