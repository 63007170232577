import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col, Alert, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../../api';

const Register = () => {
    const navigate = useNavigate();

    //user info
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    //isPrivacyChecked and isTermsChecked -- will need to be checked to set to true
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);

    //user address
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [stateVal, setStateVal] = useState('');
    const [zip, setZip] = useState('');

    //privacy policy and terms
    const [privacyPolicyText, setPrivacyPolicyText] = useState('');
    const [termsText, setTermsText] = useState('');

    // Modal states
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);

    useEffect(() => {
        const fetchPolicies = async () => {
            try {
                const privacyResp = await api.get('/privacy-policy');
                setPrivacyPolicyText(privacyResp.data);

                const termsResp = await api.get('/terms');
                setTermsText(termsResp.data);
            } catch (error) {
                console.error('Failed to load policies', error);
            }
        };
        fetchPolicies();
    }, []);

    const handleRegister = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        try {
            const response = await api.post('/register', {
                firstName,
                lastName,
                email,
                phone,
                username,
                password,
                confirmPassword,
                address1,
                address2,
                city,
                state: stateVal,
                zip,
                privacyPolicyAccepted: privacyChecked,
                termsAndConditionsAccepted: termsChecked
            });
            setMessage('Registration successful');
            // Redirect to login page after registration
            // 3-second delay before redirecting
            setTimeout(() => {
                navigate('/login');
            }, 3000);

        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(error.response.data);
            } else {
                setMessage('Registration failed');
            }
        }
    };

    return (
        <Container className="mt-5" style={{ maxWidth: '400px' }}>
            <Row className="justify-content-center">
                <Col>
                    <h1 className="text-center mb-4">Sign Up</h1>
                    <Form onSubmit={handleRegister}>
                        <Form.Group className="mb-3" controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="phone">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter phone number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="address1">
                            <Form.Label>Address Line 1 (No PO Box) (Must match Driver's License)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="123 Main St"
                                value={address1}
                                onChange={(e) => setAddress1(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="address2">
                            <Form.Label>Address Line 2 (Optional)</Form.Label>
                            <Form.Control
                                type="text"
                                value={address2}
                                onChange={(e) => setAddress2(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="city">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="state">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="State"
                                value={stateVal}
                                onChange={(e) => setStateVal(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="zip">
                            <Form.Label>Zip</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Zip Code"
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="confirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        {/* Privacy Policy */}
                        <div className="mb-3">
                            <Button variant="link" onClick={() => setShowPrivacyModal(true)}>
                                View Privacy Policy
                            </Button>
                            <Form.Check
                                type="checkbox"
                                label="I agree to the Privacy Policy"
                                checked={privacyChecked}
                                onChange={(e) => setPrivacyChecked(e.target.checked)}
                                required
                            />
                        </div>

                        {/* Terms & Conditions */}
                        <div className="mb-3">
                            <Button variant="link" onClick={() => setShowTermsModal(true)}>
                                View Terms & Conditions
                            </Button>
                            <Form.Check
                                type="checkbox"
                                label="I agree to the Terms & Conditions"
                                checked={termsChecked}
                                onChange={(e) => setTermsChecked(e.target.checked)}
                                required
                            />
                        </div>

                        <Button variant="primary" type="submit" className="w-100">
                            Sign Up
                        </Button>
                    </Form>

                    {message && (
                        <Alert
                            variant={message === 'Registration successful' ? 'success' : 'danger'}
                            className="mt-3 text-center"
                        >
                            {message}
                        </Alert>
                    )}

                    <Row className="mt-3">
                        <Col className="text-center">
                            <a href="/login" className="text-muted">
                                Already have an account? Sign in
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Privacy Policy Modal */}
            <Modal show={showPrivacyModal} onHide={() => setShowPrivacyModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {privacyPolicyText}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPrivacyModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Terms & Conditions Modal */}
            <Modal show={showTermsModal} onHide={() => setShowTermsModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Terms & Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {termsText}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowTermsModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Register;