// src/pages/ItemDetailPage.js
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import api from '../api';
import './ItemDetailPage.css';
import { parseISO, set } from 'date-fns';
import DOMPurify from 'dompurify';

const ItemDetailPage = () => {
    const { itemId } = useParams(); // Ensure itemId is extracted from URL
    const [item, setItem] = useState(null); // State for the item
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [price, setPrice] = useState(0);
    const [chargeableNights, setChargeableNights] = useState(0);

    // Fetch store hours, holidays, pricing tiers, and bookings
    const [storeHours, setStoreHours] = useState(null);
    const [holidays, setHolidays] = useState(null);
    const [bookings, setBookings] = useState(null);
    const [pricingTiers, setPricingTiers] = useState([]);
    const [rentalPolicy, setRentalPolicy] = useState({ discountClosedDaysAndHolidays: true });

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch item
                const itemResponse = await api.get(`/items/${itemId}`);
                setItem(itemResponse.data);

                // After fetching store hours
                const storeHoursResponse = await api.get('/store/hours');
                setStoreHours(storeHoursResponse.data);

                // Fetch holidays
                const holidaysResponse = await api.get('/store/holidays');
                setHolidays(holidaysResponse.data);

                // Fetch pricing tiers
                const pricingResponse = await api.get(`/pricing/item/${itemId}`);
                setPricingTiers(pricingResponse.data);

                // Fetch bookings
                const bookingsResponse = await api.get(`/rentals/availability/${itemId}`);
                setBookings(bookingsResponse.data);

                // Fetch rental policy
                const policyResponse = await api.get('/store/rental-policy');
                setRentalPolicy(policyResponse.data);

                setLoading(false);
            } catch (err) {
                setError('Failed to fetch data');
                setLoading(false);
                console.error(err);
            }
        };

        fetchData();
    }, [itemId]);

    const setToMidnight = (date) => {
        return set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    };

    const isDateDisabled = (date) => {
        const currentDate = setToMidnight(date);
        console.log('Checking date:', currentDate);

        // Disable holidays
        const isHoliday = holidays.some((holiday) => {
            const holidayDate = setToMidnight(parseISO(holiday.date));
            const isSameDate = currentDate.getTime() === holidayDate.getTime();
            if (isSameDate) {
                console.log('Date is a holiday:', currentDate);
            }
            return isSameDate;
        });

        // Disable store closed days
        const dayOfWeek = currentDate.getDay();
        const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        const dayName = daysOfWeek[dayOfWeek];

        const storeHour = storeHours.find((sh) => sh.dayOfWeek.toUpperCase() === dayName);
        if (!storeHour) {
            console.log('No storeHour found for:', dayName);
        } else {
            console.log('storeHour for', dayName, ':', storeHour);
        }

        const isStoreClosed = storeHour ? !storeHour.open : true;

        if (isStoreClosed) {
            console.log('Store is closed on:', dayName);
        }

        // Disable dates with existing bookings
        const isBooked = bookings.some((booking) => {
            const bookingStart = setToMidnight(parseISO(booking.startDate));
            const bookingEnd = setToMidnight(parseISO(booking.endDate));

            const isBetween = currentDate >= bookingStart && currentDate <= bookingEnd;
            if (isBetween) {
                console.log('Date is booked:', currentDate);
            }
            return isBetween;
        });

        if (isHoliday || isStoreClosed || isBooked) {
            console.log('Date is disabled:', currentDate);
        }

        return isHoliday || isStoreClosed || isBooked;
    };


    // Updated handleDateChange function
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end && item) {
            // Generate the list of dates between start and end dates
            const dateList = [];
            let currentDate = new Date(start);
            while (currentDate < end) {
                dateList.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }

            // Configured by the admin
            const discountClosedDaysAndHolidays = rentalPolicy.discountClosedDaysAndHolidays;

            // Determine chargeable nights (excluding closed days and holidays if discounting is enabled)
            let calculatedChargeableNights = 0;

            dateList.forEach((date) => {
                const currentDate = setToMidnight(date);

                const isHoliday = holidays.some((holiday) => {
                    const holidayDate = setToMidnight(parseISO(holiday.date));
                    return currentDate.getTime() === holidayDate.getTime();
                });

                const dayOfWeek = currentDate.getDay();
                const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
                const dayName = daysOfWeek[dayOfWeek];

                const storeHour = storeHours.find((sh) => sh.dayOfWeek.toUpperCase() === dayName);
                const isStoreClosed = storeHour ? !storeHour.open : true;

                if (discountClosedDaysAndHolidays) {
                    if (!(isStoreClosed || isHoliday)) {
                        //Only count the night if the store is open and it's not a holiday
                        calculatedChargeableNights++;
                    }
                } else {
                    // Count all nights regardless of store closed days or holidays
                    calculatedChargeableNights++;
                }
            });

            // Update state
            setChargeableNights(calculatedChargeableNights);

            // Determine the applicable pricing tier based on chargeableNights
            const sortedPricingTiers = [...pricingTiers].sort((a, b) => a.minNights - b.minNights);

            const applicableTier = sortedPricingTiers.find((tier) => {
                return (
                    calculatedChargeableNights >= tier.minNights &&
                    (tier.maxNights == null || calculatedChargeableNights <= tier.maxNights)
                );
            });

            if (applicableTier) {
                setPrice(applicableTier.pricePerNight * calculatedChargeableNights);
            } else {
                // Default pricing if no tier matches
                setPrice(item.price * calculatedChargeableNights);
            }
        } else {
            setPrice(0);
            setChargeableNights(0);
        }
    };

    const checkBookingAvailability = () => {
        if (!startDate || !endDate) return false;

        const start = setToMidnight(startDate);
        const end = setToMidnight(endDate);

        for (let booking of bookings) {
            const bookingStart = setToMidnight(parseISO(booking.startDate));
            const bookingEnd = setToMidnight(parseISO(booking.endDate));

            // Check if the selected range overlaps with any existing booking
            if (start <= bookingEnd && end >= bookingStart) {
                alert('The selected dates overlap with an existing booking. Please choose different dates.');
                return false;
            }
        }

        return true;
    };

    const handleAddToCart = async () => {
        if (!checkBookingAvailability()) {
            return;
        }

        try {
            const cartItem = {
                employeeID: "15",
                shopID: "1",
                itemID: item.itemId,
                unitPrice: price.toFixed(2),
                isLayaway: "true",
                unitQuantity: chargeableNights.toString(),
                startDate: startDate.toISOString().split('T')[0],  // YYYY-MM-DD format
                endDate: endDate.toISOString().split('T')[0]
            };

            await api.post('/cart', cartItem);

            alert('Item added to cart!');
            setStartDate(null);
            setEndDate(null);
            setPrice(0);
            setChargeableNights(0);
        } catch (error) {
            console.error('Failed to add to cart:', error);
            alert('Failed to add item to cart.');
        }
    };

    if (loading || storeHours === null || holidays === null || bookings === null) {
        return <p>Loading...</p>;
    }
    if (error) return <p>{error}</p>;
    if (!item) return <p>Item not found</p>;

    // If 'item' is not null, it's safe to access 'item.note'
    const itemNote = item.note || '<p>No details available.</p>';

    return (
        <div className="item-detail-container">
            <div className="item-detail-left">
                {item.imageUrl ? (
                    <img src={item.imageUrl} alt={item.description} className="item-detail-image" />
                ) : (
                    <div className="item-detail-placeholder">No Image Available</div>
                )}
            </div>

            <div className="item-detail-center">
                <h1 className="item-detail-title">{item.description}</h1>
                {item.customSku && <p className="item-sku">{item.customSku}</p>}

                {item.deposit && (
                    <p style={{ fontWeight: 'bold' }}>
                        Deposit: ${item.deposit.toFixed(2)}
                    </p>
                )}

                <div
                    className="item-detail-info"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(itemNote) }}
                ></div>

                <div className="pricing-tiers">
                    <h2>Pricing Tiers</h2>
                    <table className="pricing-table">
                        <thead>
                        <tr>
                            <th>Min Nights</th>
                            <th>Max Nights</th>
                            <th>Price Per Night</th>
                        </tr>
                        </thead>
                        <tbody>
                        {pricingTiers.map((tier) => (
                            <tr key={tier.id}>
                                <td>{tier.minNights}</td>
                                <td>{tier.maxNights || 'No Max'}</td>
                                <td>${tier.pricePerNight.toFixed(2)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Right Panel: Dates, Price, Add to Cart */}
            <div className="item-detail-right">
                <h2>Select Rental Dates</h2>
                <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    minDate={new Date()}
                    filterDate={(date) => !isDateDisabled(date)}
                />

                {/* Always show the "Total Price" and "Add to Cart" button */}
                <div className="rental-price">
                    <p>
                        <strong>Total Price:</strong>{' '}
                        {startDate && endDate ? `$${price.toFixed(2)}` : '$0.00'}
                    </p>
                    <button onClick={handleAddToCart}>Add to Cart</button>
                </div>
            </div>
        </div>
    );
};

export default ItemDetailPage;