// src/components/AdminLayout.js
import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import './AdminLayout.css';

const AdminLayout = () => (
    <Container>
        <h1 className="admin-dashboard-title">Admin Dashboard</h1>
        <Nav
            variant="tabs"
            defaultActiveKey="/admin"
            className="admin-nav justify-content-center"
        >
            <Nav.Item>
                <Nav.Link as={Link} to="/admin">Dashboard</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to="/admin/items">Manage Items</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to="/admin/store-hours">Store Hours</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to="/admin/holidays">Holidays</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to="/admin/rental-policy">AdminRentalPolicies</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to="/admin/bookings-calendar">AdminBookingsCalendar</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to="/admin/manage-users">Manage Users</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to="/admin/discounts">Discounts</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to="/admin/tax-category">Tax Categories</Nav.Link>
            </Nav.Item>

        </Nav>
        <Outlet />
    </Container>
);

export default AdminLayout;