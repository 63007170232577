import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ItemCard from '../components/ItemCard';
import api from '../api';
import './CameraPage.css';

const categoryMap = {
    'Canon': 148,
    'Sony': 157,
    'Nikon': 153
};

// If the user chooses "All", we fetch all camera categories
const ALL_CAMERA_IDS = [148, 157, 153];

const CameraPage = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const brand = params.get('brand') || 'All'; // now default to All

    useEffect(() => {
        const fetchCameras = async () => {
            setLoading(true);
            setError(null);

            try {
                let fetchedItems = [];

                if (brand === 'All') {
                    // Fetch from multiple categories, then merge
                    const requests = ALL_CAMERA_IDS.map(catId => api.get(`/items/category/${catId}`));
                    const responses = await Promise.all(requests);
                    // Flatten all responses
                    fetchedItems = responses.flatMap(r => r.data);
                } else {
                    // Single brand
                    const categoryId = categoryMap[brand] || categoryMap['Canon'];
                    const response = await api.get(`/items/category/${categoryId}`);
                    fetchedItems = response.data;
                }

                // Optionally remove duplicates by `itemId` (in case categories overlap)
                const seen = new Map();
                for (const i of fetchedItems) {
                    if (!seen.has(i.itemId)) {
                        seen.set(i.itemId, i);
                    }
                }
                setItems([...seen.values()]);
            } catch (err) {
                console.error(err);
                setError('Failed to fetch cameras');
            } finally {
                setLoading(false);
            }
        };

        fetchCameras();
    }, [brand]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="camera-page-container">
            <h1>{brand === 'All' ? 'All Cameras' : `${brand} Cameras`}</h1>
            <div className="items-grid">
                {items
                    .filter(item => !item.hidden)
                    .map(item => (
                        <ItemCard key={item.itemId} item={item} />
                    ))
                }
            </div>
        </div>
    );
};

export default CameraPage;