import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import './CartPage.css';

const CartPage = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userProfile, setUserProfile] = useState(null);

    // discount from user’s assigned (fetched from discountId)
    const [assignedDiscount, setAssignedDiscount] = useState(null);

    // user-typed discount name code
    const [typedDiscountCode, setTypedDiscountCode] = useState('');
    const [overrideDiscount, setOverrideDiscount] = useState(null);
    const navigate = useNavigate();

    // 1. Fetch user profile
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await api.get('/profile');
                setUserProfile(response.data);

                // If user has assignedDiscountId, fetch discount
                if (response.data.assignedDiscountId) {
                    const discResp = await api.get('/lightspeed/discounts/search', {
                        params: { discountId: response.data.assignedDiscountId }
                    });
                    setAssignedDiscount(discResp.data); // store the JSON node
                }
            } catch (err) {
                console.error(err);
                setError('Failed to load user profile');
            }
        };
        fetchUserProfile();
    }, []);

    // 2. Fetch cart items
    useEffect(() => {
        const fetchCartItems = async () => {
            try {
                const response = await api.get('/cart');
                setCartItems(response.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError('Failed to load cart items');
                setLoading(false);
            }
        };
        fetchCartItems();
    }, []);

    // If typed discount code changes, fetch & store override discount
    const handleCheckDiscount = async () => {
        if (!typedDiscountCode) {
            setOverrideDiscount(null);
            return;
        }
        try {
            // search discount by name
            const resp = await api.get('/lightspeed/discounts/search', {
                params: { name: typedDiscountCode }
            });
            setOverrideDiscount(resp.data);
        } catch (err) {
            console.error('Failed to fetch override discount', err);
            setOverrideDiscount(null);
        }
    };

    if (loading) return <p>Loading cart...</p>;
    if (error) return <p>{error}</p>;
    if (!userProfile) return <p>Loading your profile...</p>;

    // Decide which discount is currently active for them
    // If user typed a code that resolved to an active discount, we use overrideDiscount
    // else we use assignedDiscount
    const appliedDiscount = overrideDiscount || assignedDiscount;

    // 3. Calculate final line item price, factoring discount
    //    If discountPercent > 0, apply it
    //    If discountAmount > 0, subtract it
    // This is a simplified approach. Real logic might handle partial dollar discounts etc.
    const calcDiscountedPrice = (basePrice, quantity) => {
        let total = basePrice;
        if (!appliedDiscount) return total; // no discount

        const discountAmount = parseFloat(appliedDiscount.discountAmount);   // e.g. 5 dollars
        const discountPercent = parseFloat(appliedDiscount.discountPercent); // e.g. 0.2 for 20%

        // If discountPercent > 0, apply that
        if (discountPercent > 0) {
            total = total * (1 - discountPercent);
        } else if (discountAmount > 0) {
            total = total - discountAmount;
            if (total < 0) total = 0; // avoid negative
        }
        return total;
    };

    const handleRemove = async (id) => {
        try {
            await api.delete(`/cart/${id}`);
            setCartItems(cartItems.filter(ci => ci.id !== id));
        } catch (err) {
            console.error('Failed to remove item:', err);
        }
    };

    const handleConfirmBooking = async () => {
        if (cartItems.length === 0) {
            alert("Your cart is empty!");
            return;
        }

        try {
            // For each cart item, compute discounted price
            // Then pass that discounted price as the layaway SaleLine's unitPrice
            const lightspeedPayload = cartItems.map(ci => {
                const discountPrice = calcDiscountedPrice(ci.unitPrice, ci.unitQuantity).toFixed(2);
                return {
                    employeeID: ci.employeeID,
                    shopID: ci.shopID,
                    itemID: ci.itemID,
                    unitPrice: discountPrice,
                    isLayaway: "true",
                    customerID: ci.customerId,
                    unitQuantity: ci.unitQuantity.toString(),
                    startDate: ci.startDate,
                    endDate: ci.endDate
                };
            });

            const lightspeedResponse = await api.post('/lightspeed/layaway', { items: lightspeedPayload });
            if (!lightspeedResponse.data.success) {
                alert("Failed to create layaway lines in Lightspeed. Please try again.");
                return;
            }

            // Then create rental bookings
            for (const ci of cartItems) {
                const discountPrice = calcDiscountedPrice(ci.unitPrice, ci.unitQuantity);
                const booking = {
                    startDate: ci.startDate,
                    endDate: ci.endDate,
                    item: { itemId: ci.itemID },
                    customerName: `${userProfile.firstName} ${userProfile.lastName}`,
                    customerEmail: userProfile.email,
                    totalPrice: discountPrice,
                    rentalDays: ci.unitQuantity
                };
                await api.post('/rentals/book', booking);
            }

            // Clear cart
            for (const ci of cartItems) {
                await api.delete(`/cart/${ci.id}`);
            }

            // Redirect
            navigate('/confirmation');

        } catch (err) {
            console.error("Error during confirmation process:", err);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <div className="cart-container">
            <h1>Your Cart</h1>

            {/* discount code override input */}
            <div style={{ margin: '1em 0' }}>
                <label>Discount Code (Optional): </label>
                <input
                    type="text"
                    value={typedDiscountCode}
                    onChange={(e) => setTypedDiscountCode(e.target.value)}
                    placeholder="Type discount name..."
                />
                <button onClick={handleCheckDiscount}>Check Discount</button>
            </div>

            {appliedDiscount  && !overrideDiscount && (
                <div style={{ margin: '1em 0', color: 'green' }}>
                    <p>
                        Applied Discount: {appliedDiscount.name}
                        {appliedDiscount.discountPercent > 0
                            ? ` (${(appliedDiscount.discountPercent * 100).toFixed(0)}%)`
                            : appliedDiscount.discountAmount > 0
                                ? ` ($${appliedDiscount.discountAmount} off)`
                                : ''
                        }
                    </p>
                    <button onClick={() => {
                        setTypedDiscountCode('');
                        setOverrideDiscount(null);
                        setAssignedDiscount(null);
                    }}>
                        Remove Discount
                    </button>
                </div>
            )}

            {cartItems.length === 0 ? (
                <p>Your cart is empty</p>
            ) : (
                <div>
                    <div className="cart-header-row">
                        <div className="cart-header-image">Image</div>
                        <div className="cart-header-description">Item</div>
                        <div className="cart-header-dates">Start Date</div>
                        <div className="cart-header-dates">End Date</div>
                        <div className="cart-header-unitprice">Total Price</div>
                        <div className="cart-header-quantity">Qty</div>
                        <div className="cart-header-total">Price/Day</div>
                        <div className="cart-header-action">Action</div>
                    </div>

                    <div className="cart-items">
                        {cartItems.map((ci) => {
                            const originalLinePrice = parseFloat(ci.unitPrice);
                            const dailyRate = originalLinePrice / parseInt(ci.unitQuantity, 10);

                            // discounted totals
                            const discountedLinePrice = calcDiscountedPrice(originalLinePrice, ci.unitQuantity);
                            const discountedPerDay = discountedLinePrice / ci.unitQuantity;
                            const savings = originalLinePrice - discountedLinePrice;

                            return (
                                <div className="cart-item-row" key={ci.id}>
                                    <img
                                        src={ci.imageUrl || '/placeholder-image.jpg'}
                                        alt={ci.itemDescription || 'Item'}
                                        className="cart-item-image"
                                    />
                                    <div className="cart-item-description">
                                        {ci.itemDescription || 'No Description Available'}
                                    </div>
                                    <div className="cart-item-dates">{ci.startDate}</div>
                                    <div className="cart-item-dates">{ci.endDate}</div>

                                    {/* Original line total */}
                                    <div className="cart-item-unit-price">
                                        ${originalLinePrice.toFixed(2)}
                                    </div>
                                    <div className="cart-item-quantity">{ci.unitQuantity}</div>

                                    {/* Discounted line total */}
                                    <div className="cart-item-total">
                                        <div>
                                            Final: ${discountedLinePrice.toFixed(2)}
                                        </div>
                                        {appliedDiscount && savings > 0 && (
                                            <small style={{color: 'green'}}>
                                                You save ${savings.toFixed(2)}
                                            </small>
                                        )}
                                        <div className="per-day">
                                            <small>({discountedPerDay.toFixed(2)} / day)</small>
                                        </div>
                                    </div>

                                    <button
                                        className="cart-item-remove"
                                        onClick={() => handleRemove(ci.id)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            );
                        })}
                    </div>

                    <button className="confirm-booking-button" onClick={handleConfirmBooking}>
                        Confirm Booking
                    </button>
                </div>
            )}
            <p>Finalize your rental at the store. Payment will be taken upon pickup.</p>
        </div>
    );
};

export default CartPage;