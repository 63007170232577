//Profile.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import api from "../api";
import './Profile.css';

const Profile = () => {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState('');

    const [showEmailForm, setShowEmailForm] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [confirmNewEmail, setConfirmNewEmail] = useState('');
    const [emailMsg, setEmailMsg] = useState('');

    const [showWorkForm, setShowWorkForm] = useState(false);
    const [newWorkPhone, setNewWorkPhone] = useState('');
    const [workMsg, setWorkMsg] = useState('');

    const [showCompanyForm, setShowCompanyForm] = useState(false);
    const [newCompany, setNewCompany] = useState('');
    const [companyMsg, setCompanyMsg] = useState('');

    const [showAddressForm, setShowAddressForm] = useState(false);
    const [desiredAddress, setDesiredAddress] = useState('');
    const [explanation, setExplanation] = useState('');
    const [addressMsg, setAddressMsg] = useState('');

    const formatPhoneNumber = (phone) => {
        if (!phone) return '';
        const digits = phone.replace(/\D/g, '');
        if (digits.length === 10) {
            return digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
        return phone;
    };

    const fetchProfile = async () => {
        try {
            const response = await api.get('/profile', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setProfile(response.data);
        } catch (err) {
            console.error('Failed to fetch profile:', err);
            setError('Failed to fetch profile');
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    if (error) {
        return <div>{error}</div>;
    }

    if (!profile) {
        return <div className="profile-loading">Loading...</div>;
    }

    const formattedPhone = formatPhoneNumber(profile.phone);
    const formattedWorkPhone = formatPhoneNumber(profile.workPhone);

    const handleEmailUpdateRequest = async (e) => {
        e.preventDefault();
        setEmailMsg('');
        if (newEmail !== confirmNewEmail) {
            setEmailMsg('Emails do not match.');
            return;
        }

        try {
            const response = await api.post('/profile/change-email-request', null, {
                params: { newEmail },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setEmailMsg(response.data);
            if (response.data.toLowerCase().includes('success')) {
                await fetchProfile();
                setTimeout(() => {
                    setShowEmailForm(false);
                    setEmailMsg('');
                    setNewEmail('');
                    setConfirmNewEmail('');
                }, 2000);
            }
        } catch (err) {
            console.error(err);
            if (err.response && err.response.data) {
                setEmailMsg(err.response.data);
            } else {
                setEmailMsg('Failed to send verification email.');
            }
        }
    };

    const handleWorkPhoneChange = async (e) => {
        e.preventDefault();
        setWorkMsg('');
        try {
            const response = await api.put('/profile/workphone', null, {
                params: { newWorkPhone },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setWorkMsg(response.data);

            if (response.data.toLowerCase().includes('success')) {
                await fetchProfile();
                setTimeout(() => {
                    setShowWorkForm(false);
                    setWorkMsg('');
                    setNewWorkPhone('');
                }, 2000);
            }
        } catch (err) {
            console.error(err);
            if (err.response && err.response.data) {
                setWorkMsg(err.response.data);
            } else {
                setWorkMsg('Failed to update work phone.');
            }
        }
    };

    const handleCompanyChange = async (e) => {
        e.preventDefault();
        setCompanyMsg('');
        try {
            const response = await api.put('/profile/company', null, {
                params: { newCompany },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setCompanyMsg(response.data);

            if (response.data.toLowerCase().includes('success')) {
                await fetchProfile();
                setTimeout(() => {
                    setShowCompanyForm(false);
                    setCompanyMsg('');
                    setNewCompany('');
                }, 2000);
            }
        } catch (err) {
            console.error(err);
            if (err.response && err.response.data) {
                setCompanyMsg(err.response.data);
            } else {
                setCompanyMsg('Failed to update company.');
            }
        }
    };

    const handleAddressRequest = async (e) => {
        e.preventDefault();
        setAddressMsg('');
        try {
            const response = await api.post('/profile/address-request', null, {
                params: { desiredAddress, explanation },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setAddressMsg(response.data);

            if (response.data.toLowerCase().includes('success')) {
                setTimeout(() => {
                    setShowAddressForm(false);
                    setAddressMsg('');
                    setDesiredAddress('');
                    setExplanation('');
                }, 2000);
            }
        } catch (err) {
            console.error(err);
            if (err.response && err.response.data) {
                setAddressMsg(err.response.data);
            } else {
                setAddressMsg('Failed to send address change request.');
            }
        }
    };

    return (
        <Container className="mt-5 profile-container">
            <h1 className="profile-heading">My Profile</h1>
            <Row>
                <Col md={6} className="profile-left">
                    <div className="profile-section">
                        <h2 className="profile-subheading">Name & Company</h2>
                        <p><strong>First Name:</strong> {profile.firstName}</p>
                        <p><strong>Last Name:</strong> {profile.lastName}</p>
                        <p>
                            <strong>Company (Optional):</strong> {profile.company || ' '}
                            <Button variant="secondary" className="profile-update-btn" size="sm" onClick={() => setShowCompanyForm(!showCompanyForm)}>
                                Update Company
                            </Button>
                            {showCompanyForm && (
                                <Form onSubmit={handleCompanyChange} className="mt-2">
                                    <Form.Group className="mb-2">
                                        <Form.Control
                                            type="text"
                                            value={newCompany}
                                            onChange={(e) => setNewCompany(e.target.value)}
                                            placeholder="New Company"
                                            required
                                        />
                                    </Form.Group>
                                    <Button variant="secondary" size="sm" type="submit">Update Company</Button>
                                    {companyMsg && <p className="mt-2">{companyMsg}</p>}
                                </Form>
                            )}
                        </p>
                    </div>

                    <div className="profile-section">
                        <h2 className="profile-subheading">Phones</h2>
                        <p>
                            <strong>Work (Optional):</strong> {formattedWorkPhone || ' '}
                            <Button variant="secondary" className="profile-update-btn" size="sm" onClick={() => setShowWorkForm(!showWorkForm)}>
                                Update Work #
                            </Button>
                            {showWorkForm && (
                                <Form onSubmit={handleWorkPhoneChange} className="mt-2">
                                    <Form.Group className="mb-2">
                                        <Form.Control
                                            type="text"
                                            value={newWorkPhone}
                                            onChange={(e) => setNewWorkPhone(e.target.value)}
                                            placeholder="New Work Phone (###-###-####)"
                                            required
                                        />
                                    </Form.Group>
                                    <Button variant="secondary" size="sm" type="submit">Update Work #</Button>
                                    {workMsg && <p className="mt-2">{workMsg}</p>}
                                </Form>
                            )}
                        </p>
                        <p><strong>Mobile:</strong> {formattedPhone}</p>
                    </div>

                    <div className="profile-section">
                        <h2 className="profile-subheading">Account Info</h2>
                        <p><strong>Username:</strong> {profile.username}</p>
                        <p>
                            <strong>Email:</strong> {profile.email}
                            <Button variant="secondary" className="profile-update-btn" size="sm" onClick={() => setShowEmailForm(!showEmailForm)}>
                                Change Email
                            </Button>
                        </p>
                        {showEmailForm && (
                            <Form onSubmit={handleEmailUpdateRequest} className="mt-2">
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        type="email"
                                        value={newEmail}
                                        onChange={(e) => setNewEmail(e.target.value)}
                                        placeholder="New Email"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Control
                                        type="email"
                                        value={confirmNewEmail}
                                        onChange={(e) => setConfirmNewEmail(e.target.value)}
                                        placeholder="Confirm New Email"
                                        required
                                    />
                                </Form.Group>
                                <Button variant="secondary" size="sm" type="submit">Update Email</Button>
                                {emailMsg && <p className="mt-2">{emailMsg}</p>}
                            </Form>
                        )}

                        <p><strong>Customer ID:</strong> {profile.customerId}</p>
                    </div>
                </Col>

                <Col md={6} className="profile-right">
                    <div className="profile-section">
                        <h2 className="profile-subheading">Address</h2>
                        <p><strong>Address 1:</strong> {profile.address1}</p>
                        <p><strong>Address 2:</strong> {profile.address2 || ' '}</p>
                        <p><strong>City:</strong> {profile.city}</p>
                        <p><strong>State:</strong> {profile.stateVal}</p>
                        <p><strong>Zip:</strong> {profile.zip}</p>
                        <Button variant="primary" size="sm" className="change-address-btn" onClick={() => setShowAddressForm(!showAddressForm)}>
                            Request Change of Address
                        </Button>

                        {showAddressForm && (
                            <Form onSubmit={handleAddressRequest} className="mt-2">
                                <p><strong>Current Info:</strong></p>
                                <p>Name: {profile.firstName} {profile.lastName}</p>
                                <p>Email: {profile.email}</p>
                                <p>Phone: {formattedPhone}</p>
                                <Form.Group className="mb-2">
                                    <Form.Label>New Desired Address & Explanation</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        value={desiredAddress}
                                        onChange={(e) => setDesiredAddress(e.target.value)}
                                        placeholder="Enter new address and any details here..."
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>Explanation</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        value={explanation}
                                        onChange={(e) => setExplanation(e.target.value)}
                                        placeholder="Why do you need this address changed?"
                                        required
                                    />
                                </Form.Group>
                                <p className="mt-2" style={{fontSize: '0.9em', color:'#555'}}>
                                    Reminder: The new address must match your Driver's License.
                                </p>
                                <Button variant="primary" size="sm" type="submit">Submit Request</Button>
                                {addressMsg && <p className="mt-2">{addressMsg}</p>}
                            </Form>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;