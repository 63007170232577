// src/pages/AdminTaxPage.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import './AdminTaxPage.css';

const AdminTaxPage = () => {
    const [taxCategories, setTaxCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchTaxes = async () => {
        try {
            const response = await api.get('/lightspeed/taxes');
            setTaxCategories(response.data);
        } catch (err) {
            console.error('Failed to load tax categories:', err);
            setError('Failed to load tax categories');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTaxes();
    }, []);

    const handleToggleActive = async (taxCategoryId, currentActive) => {
        const newActive = !currentActive;
        try {
            await api.put(`/lightspeed/taxes/${taxCategoryId}/active?active=${newActive}`);
            // Re-fetch or update local state
            fetchTaxes();
        } catch (err) {
            console.error('Failed to toggle tax active state:', err);
            alert('Failed to toggle tax active state');
        }
    };

    const handleSetDefault = async (taxCategoryId) => {
        try {
            await api.put(`/lightspeed/taxes/${taxCategoryId}/default`);
            // Re-fetch or update local state
            fetchTaxes();
        } catch (err) {
            console.error('Failed to set default tax category:', err);
            alert('Failed to set default tax category');
        }
    };

    if (loading) return <p className="loading-text">Loading tax categories...</p>;
    if (error) return <p className="error-text">{error}</p>;

    return (
        <div className="admin-tax-container">
            <h1 className="admin-tax-title">Lightspeed Tax Categories</h1>
            {taxCategories.length === 0 ? (
                <p>No tax categories found.</p>
            ) : (
                <table className="admin-tax-table">
                    <thead>
                    <tr>
                        <th>TaxCategoryID</th>
                        <th>Tax1 Name</th>
                        <th>Tax1 Rate</th>
                        <th>Tax2 Name</th>
                        <th>Tax2 Rate</th>
                        <th>Is Tax Inclusive</th>
                        <th>Timestamp</th>
                        <th>Active?</th>
                        <th>Default?</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {taxCategories.map((tax) => {
                        const isActive = tax.active;
                        const isDefault = tax.default;
                        return (
                            <tr key={tax.taxCategoryID}>
                                <td>{tax.taxCategoryID}</td>
                                <td>{tax.tax1Name}</td>
                                <td>{tax.tax1Rate}</td>
                                <td>{tax.tax2Name}</td>
                                <td>{tax.tax2Rate}</td>
                                <td>{tax.taxInclusive ? 'Yes' : 'No'}</td>
                                <td>{tax.timeStamp}</td>
                                <td>{isActive ? 'Active' : 'Inactive'}</td>
                                <td>{isDefault ? 'Yes' : 'No'}</td>
                                <td>
                                    <button
                                        onClick={() => handleToggleActive(tax.taxCategoryID, isActive)}
                                        className="toggle-active-btn"
                                    >
                                        {isActive ? 'Deactivate' : 'Activate'}
                                    </button>
                                    &nbsp;
                                    <button
                                        onClick={() => handleSetDefault(tax.taxCategoryID)}
                                        className="toggle-default-btn"
                                    >
                                        {isDefault ? 'Default' : 'Set Default'}
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AdminTaxPage;