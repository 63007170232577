// src/pages/VideoProductionPage.js
import React, { useEffect, useState } from 'react';
import ItemCard from '../components/ItemCard';
import api from '../api';
import './VideoProductionPage.css';

const VIDEO_CATEGORIES = [239, 243];
// 239 = Lighting, 243 = Video/Audio Accessories, etc.

const VideoProductionPage = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVideoItems = async () => {
            try {
                const requests = VIDEO_CATEGORIES.map(catId => api.get(`/items/category/${catId}`));
                const responses = await Promise.all(requests);
                // Flatten
                const combined = responses.flatMap(res => res.data);

                // Filter out hidden items or duplicates
                const seen = new Map();
                for (const i of combined) {
                    if (!seen.has(i.itemId) && !i.hidden) {
                        seen.set(i.itemId, i);
                    }
                }

                setItems(Array.from(seen.values()));
            } catch (err) {
                setError('Failed to fetch video production items');
            } finally {
                setLoading(false);
            }
        };
        fetchVideoItems();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="video-production-container">
            <h1>Video Production</h1>
            <div className="items-grid">
                {items.map(item => (
                    <ItemCard key={item.itemId} item={item} />
                ))}
            </div>
        </div>
    );
};

export default VideoProductionPage;
