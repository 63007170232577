import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Alert } from 'react-bootstrap';
import api from '../api';

const VerifyEmailPage = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [message, setMessage] = useState('Verifying...');
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            if (!token) {
                setMessage('Invalid token.');
                return;
            }

            try {
                const response = await api.get('/profile/verify-email', { params: { token } });
                setMessage(response.data);

                // Show success message for a few seconds, then redirect to /profile
                setTimeout(() => {
                    navigate('/profile');
                }, 3000); // 3 second delay
            } catch (err) {
                console.error(err);
                if (err.response && err.response.data) {
                    setMessage(err.response.data);
                } else {
                    setMessage('Failed to verify email.');
                }
            }
        };

        verifyEmail();
    }, [token, navigate]);

    return (
        <Container className="mt-5">
            <Alert variant="info" className="text-center">
                {message}
            </Alert>
        </Container>
    );
};

export default VerifyEmailPage;
