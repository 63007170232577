// src/components/common/Header.js
import React, { useContext, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { Navbar, Nav, Container, Button, NavDropdown } from 'react-bootstrap';
import './Header.css';

const Header = () => {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    // State to control hover for Cameras dropdown
    const [showCameras, setShowCameras] = useState(false);
    // State to control hover for Lenses dropdown
    const [showLenses, setShowLenses] = useState(false);

    // Handle direct click on "Cameras" text
    const handleCamerasClick = (e) => {
        e.preventDefault();
        navigate('/cameras?brand=All');
    };

    // Handle direct click on "Lenses" text
    const handleLensesClick = (e) => {
        e.preventDefault();
        navigate('/lenses?brand=All');
    };

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">

                    {user ? (
                        <>
                            {/* Left side Nav items */}
                            <Nav className="me-auto">
                                {/* Cameras */}
                                <NavDropdown
                                    title={
                                        <span onClick={handleCamerasClick}>
                      Cameras
                    </span>
                                    }
                                    id="camera-brand-dropdown"
                                    show={showCameras}
                                    onMouseEnter={() => setShowCameras(true)}
                                    onMouseLeave={() => setShowCameras(false)}
                                >
                                    <NavDropdown.Item as={Link} to="/cameras?brand=Canon">
                                        Canon
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/cameras?brand=Sony">
                                        Sony
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/cameras?brand=Nikon">
                                        Nikon
                                    </NavDropdown.Item>
                                </NavDropdown>

                                {/* Lenses */}
                                <NavDropdown
                                    title={
                                        <span onClick={handleLensesClick}>
                      Lenses
                    </span>
                                    }
                                    id="lens-brand-dropdown"
                                    show={showLenses}
                                    onMouseEnter={() => setShowLenses(true)}
                                    onMouseLeave={() => setShowLenses(false)}
                                >
                                    <NavDropdown.Item as={Link} to="/lenses?brand=Canon">
                                        Canon
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/lenses?brand=Sony">
                                        Sony
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/lenses?brand=Nikon">
                                        Nikon
                                    </NavDropdown.Item>
                                </NavDropdown>

                                <Nav.Link as={NavLink} to="/lightings">Lighting</Nav.Link>
                                <Nav.Link as={NavLink} to="/accessories">Accessories</Nav.Link>
                                <Nav.Link as={NavLink} to="/projectors">Projectors</Nav.Link>
                                <Nav.Link as={NavLink} to="/all-items">All Rental Items</Nav.Link>
                                <Nav.Link as={NavLink} to="/cart">Cart</Nav.Link>

                                {/* Admin Links */}
                                {user.roles.includes('ROLE_ADMIN') && (
                                    <NavDropdown title="Admin" id="admin-nav-dropdown">
                                        <NavDropdown.Item as={Link} to="/admin">
                                            Dashboard
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/items">
                                            Manage Items
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/store-hours">
                                            Store Hours
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/holidays">
                                            Holidays
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/rental-policy">
                                            Rental Policies
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/bookings-calendar">
                                            Bookings Calendar
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/manage-users">
                                            Manage Users
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/discounts">
                                            Discounts
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/admin/tax-category">
                                            Tax Categories
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                )}
                            </Nav>

                            {/* Right side: Logout + Profile */}
                            <Nav>
                                <Button variant="outline-light" onClick={logout}>
                                    Logout
                                </Button>
                                <Nav.Link as={NavLink} to="/profile" className="ms-2">
                                    Profile
                                </Nav.Link>
                            </Nav>
                        </>
                    ) : (
                        // If user is not logged in:
                        <Nav className="me-auto">
                            <Nav.Link as={NavLink} to="/login">Login</Nav.Link>
                            <Nav.Link as={NavLink} to="/register">Register</Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;