import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import api from '../api';
import './AdminItemsPage.css';

const AdminItemsPage = () => {
    const [items, setItems] = useState([]);
    const [sortOption, setSortOption] = useState('description');
    const [categoryMappings, setCategoryMappings] = useState({});
    const [mainCategory, setMainCategory] = useState('All');
    const [subCategory, setSubCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    // 1. Fetch items & category mappings on mount
    useEffect(() => {
        fetchAllItems();
        fetchCategoryMappings();
    }, []);

    const fetchAllItems = async () => {
        try {
            const response = await api.get('/items/all');
            setItems(response.data);
        } catch (err) {
            console.error('Failed to fetch items:', err);
        }
    };

    const fetchCategoryMappings = async () => {
        try {
            const response = await api.get('/category-mappings');
            setCategoryMappings(response.data);
        } catch (err) {
            console.error('Failed to fetch category mappings', err);
        }
    };

    // 2. The toggleHide function
    async function toggleHide(itemId, hidden) {
        try {
            await api.put(`/items/admin/items/${itemId}/toggle-hide`);
            // After toggling, refetch or update local state so UI refreshes
            fetchAllItems();
        } catch (err) {
            console.error('Failed to toggle hide state:', err);
            alert('Error toggling hide/unhide');
        }
    }

    // Sorting

    // 3. Sorting
    const sortedItems = [...items].sort((a, b) => {
        if (sortOption === 'lastUpdated') {
            const dateA = a.lastUpdated ? new Date(a.lastUpdated) : new Date(0);
            const dateB = b.lastUpdated ? new Date(b.lastUpdated) : new Date(0);
            return dateB - dateA; // descending by lastUpdated
        }
        else if (sortOption === 'hidden') {
            // Sort hidden items to the bottom; not hidden to the top
            // a.hidden is true => item "should appear later"
            // Return -1 if a should come before b; 1 otherwise
            // This is essentially comparing booleans
            if (a.hidden === b.hidden) return 0;
            return a.hidden ? -1 : 1;
        }
        else {
            // Default to "description" ascending
            return (a.description || '').localeCompare(b.description || '');
        }
    });

    // Build list of main categories from categoryMappings keys
    const mainCategories = Object.keys(categoryMappings);

    // Subcategories
    let subCategories = [];
    if (mainCategory !== 'All' && categoryMappings[mainCategory]) {
        const val = categoryMappings[mainCategory];
        if (typeof val === 'object' && !Array.isArray(val)) {
            subCategories = Object.keys(val);
        }
    }

    // Category filter
    const filteredByCategory = sortedItems.filter(item => {
        if (mainCategory === 'All') return true;

        // item.category is an object => { categoryId: 148, name: "...", etc. }
        const catId = item.category ? item.category.categoryId : null;
        const val = categoryMappings[mainCategory];

        if (typeof val === 'object' && !Array.isArray(val)) {
            if (subCategory) {
                const arr = val[subCategory] || [];
                return arr.includes(catId);
            } else {
                const allCatIds = Object.values(val).flat();
                return allCatIds.includes(catId);
            }
        } else {
            const arr = val || [];
            return arr.includes(catId);
        }
    });

    // Search filter
    const finalFilteredItems = filteredByCategory.filter(item => {
        const sTerm = searchTerm.toLowerCase().trim();
        if (!sTerm) return true;

        const desc = (item.description || '').toLowerCase();
        const upc = (item.upc || '').toLowerCase();
        const ean = (item.ean || '').toLowerCase();
        const customSku = (item.customSku || '').toLowerCase();
        const manufacturerSku = (item.manufacturerSku || '').toLowerCase();

        return (
            desc.includes(sTerm) ||
            upc.includes(sTerm) ||
            ean.includes(sTerm) ||
            customSku.includes(sTerm) ||
            manufacturerSku.includes(sTerm)
        );
    });

    return (
        <div className="admin-items-container">
            <h1>Admin - Items</h1>

            <div className="admin-items-controls">
                {/* Sort dropdown */}
                <label className="control-label">
                    Sort by:
                    <select
                        value={sortOption}
                        onChange={(e) => setSortOption(e.target.value)}
                    >
                        <option value="description">Description</option>
                        <option value="lastUpdated">Last Updated</option>
                        <option value="hidden">Hidden</option>
                    </select>
                </label>

                {/* Main Category */}
                <label className="control-label">
                    Main Category:
                    <select
                        value={mainCategory}
                        onChange={(e) => {
                            setMainCategory(e.target.value);
                            setSubCategory('');
                        }}
                    >
                        {mainCategories.map(mc => (
                            <option key={mc} value={mc}>
                                {mc}
                            </option>
                        ))}
                    </select>
                </label>

                {/* Subcategory */}
                {subCategories.length > 0 && (
                    <label className="control-label">
                        Subcategory:
                        <select
                            value={subCategory}
                            onChange={(e) => setSubCategory(e.target.value)}
                        >
                            <option value="">(All)</option>
                            {subCategories.map(sub => (
                                <option key={sub} value={sub}>
                                    {sub}
                                </option>
                            ))}
                        </select>
                    </label>
                )}

                {/* Search input */}
                <label className="control-label">
                    Search:
                    <input
                        type="text"
                        placeholder="Description or SKU..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </label>
            </div>

            {finalFilteredItems.length === 0 ? (
                <p>No items found.</p>
            ) : (
                <ul className="admin-items-list">
                    {finalFilteredItems.map(item => {
                        const catId = item.category ? item.category.categoryId : null;
                        return (
                            <li key={item.itemId}>
                                <div className="admin-item-info">
                                    <span className="admin-item-description">
                                        {item.description || 'Unnamed Item'}
                                    </span>
                                    <span className="admin-item-category">
                                        CategoryID: {catId || '—'}
                                    </span>
                                    {item.lastUpdated && (
                                        <span className="admin-item-timestamp">
                                            Last Updated: {format(new Date(item.lastUpdated), 'yyyy-MM-dd HH:mm')}
                                        </span>
                                    )}
                                </div>
                                <div className="admin-item-actions">
                                    <Link to={`/admin/items/${item.itemId}/pricing`}>Manage Pricing</Link>
                                    <Link to={`/admin/items/${item.itemId}/details`}>Manage Details</Link>
                                </div>

                                {/* Hide/Unhide button */}
                                <button
                                    onClick={() => toggleHide(item.itemId, item.hidden)}
                                    className="hide-toggle-btn"
                                >
                                    {item.hidden ? 'Unhide' : 'Hide'}
                                </button>

                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default AdminItemsPage;